import CryptoJS from "crypto-js";

// Hàm giải mã JSON
export function decryptJSON(encryptedData, password) {
  try {
    // Không cần sử dụng key trong trường hợp này
    const decrypted = CryptoJS.AES.decrypt(encryptedData, password);
    const jsonString = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(jsonString);
  } catch (error) {
    console.error("Lỗi khi giải mã:", error);
    return null;
  }
}

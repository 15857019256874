import React from "react";
import { HashRouter as Router, Routes, Route, Link } from "react-router-dom";
import LaraOpen from "./Components/LaraOpen";
import PlayWithSVG from "./Components/PlayWithSVG";
import MusicSpectrum from "./Components/MusicSpectrum";
import TestElevenlabs from "./Components/TestElevenlabs";

import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/lara" element={<LaraOpen />} />
          <Route path="/svg" element={<PlayWithSVG />} />
          <Route path="/music" element={<MusicSpectrum />} />
          <Route path="/tts" element={<TestElevenlabs />} />
        </Routes>
      </div>
    </Router>
  );
}

// Tạo component Home riêng biệt
const Home = () => {
  return (
    <div className="home-container">
      <Link to="/lara" className="lara-button">
        Chat with Lara
      </Link>
      <Link to="/svg" className="svg-button">
        Play with SVG
      </Link>
      <Link to="/music" className="music-button">
        Music Spectrum
      </Link>
      <Link to="/tts" className="tts-button">
        Text to Speech
      </Link>
    </div>
  );
};

export default App;

import React, { useState, useEffect, useRef } from "react";

const TestElevenlabs = () => {
  const [text, setText] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const websocketRef = useRef(null);
  const audioContextRef = useRef(null);
  const audioQueueRef = useRef([]);

  const ELEVENLABS_API_KEY = process.env.REACT_APP_ELEVENLABS_API_KEY;
  const voiceId = "Xb7hH8MSUJpSbSDYk0k2";
  const model = "eleven_turbo_v2";

  useEffect(() => {
    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const connectWebSocket = () => {
    const uri = `wss://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream-input?model_id=${model}`;

    websocketRef.current = new WebSocket(uri);

    websocketRef.current.onopen = () => {
      setIsConnected(true);
      console.log("WebSocket connected");

      const bosMessage = {
        text: " ",
        try_trigger_generation: true,
        generation_config: {
          chunk_length_schedule: [120, 160, 250, 290],
        },
        xi_api_key: ELEVENLABS_API_KEY,
      };
      websocketRef.current.send(JSON.stringify(bosMessage));
    };

    websocketRef.current.onmessage = async (event) => {
      const response = JSON.parse(event.data);

      if (response.audio) {
        const audioData = Uint8Array.from(atob(response.audio), (c) =>
          c.charCodeAt(0)
        );
        playAudioChunk(audioData);
      }

      if (response.isFinal) {
        setIsPlaying(false);
      }
    };

    websocketRef.current.onerror = (error) => {
      console.error("WebSocket error:", error);
      setIsConnected(false);
      setIsPlaying(false);
    };

    websocketRef.current.onclose = () => {
      setIsConnected(false);
      setIsPlaying(false);
    };
  };

  const playAudioChunk = async (audioData) => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
    }

    try {
      const audioBuffer = await audioContextRef.current.decodeAudioData(
        audioData.buffer
      );
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContextRef.current.destination);
      source.start();
    } catch (error) {
      console.error("Error playing audio chunk:", error);
    }
  };

  const handleStartStreaming = () => {
    if (!text || isPlaying) return;

    setIsPlaying(true);
    if (!isConnected) {
      connectWebSocket();
    }

    const message = {
      text: text,
      try_trigger_generation: true,
      xi_api_key: ELEVENLABS_API_KEY,
    };

    if (websocketRef.current) {
      websocketRef.current.send(JSON.stringify(message));
    }
  };

  const handleStopStreaming = () => {
    if (websocketRef.current) {
      const eosMessage = {
        text: "",
      };
      websocketRef.current.send(JSON.stringify(eosMessage));
    }
    setIsPlaying(false);
  };

  return (
    <div className="elevenlabs-container">
      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Nhập văn bản cần chuyển thành giọng nói..."
      />
      <div className="button-group">
        <button onClick={handleStartStreaming} disabled={isPlaying || !text}>
          {isPlaying ? "Đang phát..." : "Bắt đầu"}
        </button>
        <button onClick={handleStopStreaming} disabled={!isPlaying}>
          Dừng
        </button>
      </div>
    </div>
  );
};

export default TestElevenlabs;

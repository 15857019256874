import React, { useState, useEffect, useRef } from "react";
import "../Styles/style.css";
import axios from "axios";
import RequestHeader from "../Templates/RequestHeader.json";
import RequestFooter from "../Templates/RequestFooter.json";
import schema from "../Templates/schema.json";
import OpenAI from "openai";
import { decryptJSON } from "../utils/encryption";

// Assets
import BellIcon from "../Assets/Models=Bell, Size=24, Types=Outline.svg";
import SettingIcon from "../Assets/Models=Setting, Size=24, Types=Outline.svg";
import MessageIcon from "../Assets/Models=text fields, Size=24, Types=Outline.svg";
import ImageIcon from "../Assets/Models=image sending via chat, Size=24, Types=Outline.svg";
import AudioIcon from "../Assets/Models=Medium Volume, Size=24, Types=Outline.svg";
import PointerIcon from "../Assets/Models=pointer, Size=24, Types=Outline.svg";

const EncryptedConfig =
  "U2FsdGVkX1/ovU4/RblNMqHM+2jqakWhWa5dJiwxkVZ85+boY/4saHYAz4BTflNc/r4D+6tw+WIH/+I7ElExj9QBm8mgM6VMfVdNLn/85m8IHajUyC9eHkeXucOqb7WUhPQoSfbq0lvlp2UHLWZPBcCM0KR6msORnCLC3yE/OGQ/s9pFG/v1EcFjO0a3/XX+4fcKP8gWCp9NjhDqyc8vSegQGMW4c2b5AnOXyepVU97W7zjYf+NkHo6Fcq23/mRPd7oKYzU13uH8QhL+ITo/ZKAQlOo8E8/HKn9s9o7jWu56V3XbtetOT9Z37BQ0gD9nUhsL5yeiGfsVeC1wBuaXJ4heUTRF4KGGVmRYpu33osemUf1n0jUhuic0iCAcKata";

const encryptedConfig = decryptJSON(EncryptedConfig, "1231213");

// Configs
const SpreadsheetAPI = encryptedConfig.SpreadsheetAPI;

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

var MessageLogs = [];
var isFirstBoot = false;
var isSpeakBusy = false;
var pairs = 16;

var context = null;
var analyserr = null;
// var isCompFullLoaded = false;

// Thêm constant cho Gemini API key
const GEMINI_API_KEY = encryptedConfig.GEMINI_API_KEY;

// Thêm constant cho Text-to-Speech API
const TTS_API_KEY = GEMINI_API_KEY; // Sử dụng cùng API key với Gemini

// Thêm function mới cho Gemini
const callGemini = async (prompt) => {
  try {
    // Đặt isBusyState thành true

    const response = await fetch(
      `https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent?key=${GEMINI_API_KEY}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          contents: [
            {
              parts: [{ text: prompt }],
            },
          ],
        }),
      }
    );

    const data = await response.json();
    console.log("Gemini API response:", data);

    const responseText = data.candidates[0].content.parts[0].text;

    try {
      const jsonMatch = responseText.match(/```json\n([\s\S]*?)\n```/);
      if (jsonMatch && jsonMatch[1]) {
        const parsedJson = JSON.parse(jsonMatch[1]);
        return parsedJson;
      }

      return JSON.parse(responseText);
    } catch (parseError) {
      console.error("Error parsing JSON from response:", parseError);
      return {
        isFinal: true,
        isConfuse: false,
        contents: responseText.replace(/```json\n|\n```/g, "").trim(),
      };
    }
  } catch (error) {
    console.error("Error calling Gemini:", error);
    throw error;
  }
};

const PromptGenerator = (currentInput, messageHistory, maxHistory = 10) => {
  // Format message history
  const formattedHistory = messageHistory
    .slice(0, maxHistory)
    .map((log) => {
      let history = "";
      if (log.Request) {
        history += `user: ${log.Request}\n`;
      }
      if (log.LocalResolve) {
        history += `Lara: ${log.LocalResolve}\n`;
      }
      if (log.TimeStamp) {
        history += `TimeStamp: ${log.TimeStamp}`;
      }
      return history;
    })
    .join("\n-----\n");
  // Format explanation from RequestFooter
  const formattedExplanation = RequestFooter.map(
    (item) => `${item.name}: ${item.role}`
  ).join("\n");

  const prompt = `${RequestHeader}

### MESSAGE HISTORY

${formattedHistory}

CURRENT CHAT: ${currentInput}

### Please be mindful of this and return your response in JSON format according to the following schema:

${JSON.stringify(schema, null, 2)}

Do not include any text outside of this JSON object.

### Explanation
${formattedExplanation}`;

  return prompt;
};

// Function để lấy audio URL từ Google Text-to-Speech
const getAudioUrl = async (text) => {
  try {
    console.log(
      "Sending request to Google Text-to-Speech API with contents:" + text
    );
    const response = await fetch(
      `https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=${TTS_API_KEY}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          input: { text: text },
          voice: { languageCode: "en-US", name: "en-US-Journey-F" },
          audioConfig: { audioEncoding: "MP3" },
        }),
      }
    );

    const responseData = await response.json();

    if (responseData.audioContent) {
      console.log("Audio content received");
      return `data:audio/mp3;base64,${responseData.audioContent}`;
    } else {
      throw new Error("No audio content in the response");
    }
  } catch (error) {
    console.error("Error getting audio URL:", error);
    throw error;
  }
};

const LaraOpen = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [isBootFinish, setIsBootFinish] = useState(false);
  const audioRef = useRef(null);
  const animationRef = useRef(null);

  // State cho các điểm điều khiển SVG
  const [point1, setPoint1] = useState({ y: 100 });
  const [point2, setPoint2] = useState({ x: 0 });
  const [point3, setPoint3] = useState({ y: 0 });
  const [point4, setPoint4] = useState({ x: 100 });

  // Thêm biến điều khiển biên độ
  const SpectrumAMP = 20;

  const effectRan = useRef(false);

  // Thêm audioPlayerRef
  // const audioPlayerRef = useRef(null);

  // Thêm function xử lý spectrum riêng trong component

  useEffect(() => {
    // Khởi tạo Audio Context

    context = new (window.AudioContext || window.webkitAudioContext)();
    analyserr = context.createAnalyser();
    analyserr.fftSize = 2048;

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      if (context.state !== "closed") {
        context.close();
      }
    };
  }, []);
  const handleAudioSpectrum = (audioElement) => {
    if (!context || !analyserr) return;

    try {
      const source = context.createMediaElementSource(audioElement);

      analyserr.fftSize = 2048;
      analyserr.smoothingTimeConstant = 0.8;

      source.connect(analyserr);
      analyserr.connect(context.destination);

      audioElement.addEventListener("play", () => {
        isSpeakBusy = true;
        updateSpectrum();
      });

      audioElement.addEventListener("ended", () => {
        isSpeakBusy = false;
        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current);
        }
        setPoint1({ y: 100 });
        setPoint2({ x: 0 });
        setPoint3({ y: 0 });
        setPoint4({ x: 100 });
      });
    } catch (error) {
      console.error("Error in handleAudioSpectrum:", error);
    }
  };
  // Tách playAudio thành function độc lập, không xử lý spectrum
  const playAudio = async (audioUrl) => {
    try {
      // Initialize audio context if it doesn't exist
      if (context === null) {
        context = new (window.AudioContext || window.webkitAudioContext)();
      }

      let analyserr = context.createAnalyser();
      analyserr.fftSize = 2048;

      const audioPlayer = new Audio(audioUrl);

      if (context.state === "suspended") {
        await context.resume();
      }
      handleAudioSpectrum(audioPlayer);
      await audioPlayer.play();

      return new Promise((resolve, reject) => {
        audioPlayer.onended = () => resolve();
        audioPlayer.onerror = (error) => reject(error);
      });
    } catch (error) {
      throw error;
    }
  };

  const spreadSheetGet = async () => {
    if (isFirstBoot === false) {
      return [];
    } else {
      try {
        const response = await axios.get(`${SpreadsheetAPI}?pairs=${pairs}`);
        if (!Array.isArray(response.data)) {
          return [];
        }

        const cleanData = response.data
          .map((item) => ({
            Request: item.Request || "",
            LocalResolve: item.LocalResolve || "",
            ResolveA: item.ResolveA || "",
            ResolveB: item.ResolveB || "",
            ResolveC: item.ResolveC || "",
            Concluded: item.Concluded || "",
            isFinal: Boolean(item.isFinal),
            TimeStamp: item.TimeStamp || "",
            ModelArray: item.ModelArray || "",
          }))
          .filter((item) => item.Request);

        return cleanData;
      } catch (error) {
        return [];
      }
    }
  };

  useEffect(() => {
    document.title = "Lara";
    if (effectRan.current === false) {
      const fetchData = async () => {
        isFirstBoot = true;
        const data = await spreadSheetGet();
        if (data.length > 0) {
          MessageLogs = data;

          // Transform MessageLogs to messages format
          const formattedMessages = MessageLogs.flatMap((log) => {
            const messages = [];
            // Add AI responses
            [log.LocalResolve, log.ResolveA, log.ResolveB, log.ResolveC]
              .filter((response) => response) // Remove empty responses
              .forEach((response) => {
                messages.push({
                  role: "assistant",
                  content: response,
                });
              });
            // Add user message
            if (log.Request) {
              messages.push({
                role: "user",
                content: log.Request,
              });
            }

            return messages;
          });

          setMessages(formattedMessages);
          console.log("MessageLogs:", formattedMessages);
        }
        setIsBootFinish(true);
      };

      fetchData();
      effectRan.current = true;
    }
  }, []);

  const formatContent = (content) => {
    return (
      content
        // Replace line breaks with <br>
        .replace(/\n/g, "<br>")
        // Replace bold markdown (**text**) with <strong> tags
        .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
        // Replace italic markdown (*text*) with <i> tags
        .replace(/\*(.*?)\*/g, "<i>$1</i>")
    );
  };
  const formatContentForSpeech = (content) => {
    return (
      content
        // Xóa các ký tự xuống dòng
        .replace(/\n/g, " ")
        // Xóa tất cả dấu *
        .replace(/\*/g, "")
        // Xóa các khoảng trắng thừa
        .replace(/\s+/g, " ")
        .trim()
    );
  };
  const PushSpreadsheet = async (chatData) => {
    try {
      const response = await fetch(SpreadsheetAPI, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type",
        },
        credentials: "omit",
        body: JSON.stringify(chatData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Spreadsheet response:", result);
      return result;
    } catch (error) {
      console.error("Error pushing to spreadsheet:", error);
      throw error;
    }
  };

  const CloudModelRequest = async (requestContent) => {
    try {
      const prompt = PromptGenerator(requestContent, MessageLogs);
      console.log("Prompt before sent:", prompt);

      let aiResponse;

      const parsedResponse = await callGemini(prompt);

      if (parsedResponse && parsedResponse.contents) {
        aiResponse = {
          role: "assistant",
          content: parsedResponse.contents,
          concluded: parsedResponse.concluded,
          model: "Gemini Flash",
        };
      } else {
        aiResponse = {
          role: "assistant",
          content:
            "I'm having trouble understanding. Could you please rephrase that?",
        };
      }

      if (!aiResponse) {
        aiResponse = {
          role: "assistant",
          content: "All models are busy. Please try again later.",
        };
      }

      // Tạo log entry từ response
      const logEntry = {
        Request: requestContent,
        LocalResolve: aiResponse.content,
        ResolveA: "",
        ResolveB: "",
        ResolveC: "",
        Concluded: aiResponse.concluded,
        isFinal: true,
        TimeStamp: new Date().toISOString(),
        ModelArray: aiResponse.model,
      };

      return {
        aiResponse,
        logEntry,
      };
    } catch (error) {
      console.error("Error in CloudModelRequest:", error);
      return {
        aiResponse: {
          role: "assistant",
          content: "Sorry, I encountered an error. Please try again later.",
        },
        logEntry: {
          Request: requestContent,
          LocalResolve: "Error occurred",
          ResolveA: "",
          ResolveB: "",
          ResolveC: "",
          Concluded: "",
          isFinal: true,
          TimeStamp: new Date().toISOString(),
          ModelArray: "",
        },
      };
    }
  };

  // Cập nhật lại handleSubmit để sử dụng CloudModelRequest
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    setLoading(true);
    setInput("");
    const userMessage = { role: "user", content: input };
    setMessages((prev) => [userMessage, ...prev]);
    try {
      const { aiResponse, logEntry } = await CloudModelRequest(input);

      setMessages((prev) => [aiResponse, ...prev]);

      // Xử lý audio và spreadsheet
      const audioUrl = await getAudioUrl(
        formatContentForSpeech(aiResponse.content)
      );
      playAudio(audioUrl);

      // Gọi PushSpreadsheet mà không chờ đợi kết quả
      PushSpreadsheet(logEntry).catch((error) => {
        console.error("Error pushing to spreadsheet:", error);
      });
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      const errorResponse = {
        role: "assistant",
        content: "Sorry, I encountered an error. Please try again later.",
      };
      setMessages((prev) => [errorResponse, ...prev]);
    } finally {
      setLoading(false);
      setInput("");
    }
  };
  // Thêm function xử lý voice command
  const handleVoiceCommand = async (command) => {
    setLoading(true);
    const userMessage = { role: "user", content: command };
    setMessages((prev) => [userMessage, ...prev]);
    try {
      const { aiResponse, logEntry } = await CloudModelRequest(command);

      setMessages((prev) => [aiResponse, ...prev]);
      // Xử lý audio và spreadsheet
      const audioUrl = await getAudioUrl(
        formatContentForSpeech(aiResponse.content)
      );
      playAudio(audioUrl);

      // Gọi PushSpreadsheet
      PushSpreadsheet(logEntry).catch((error) => {
        console.error("Error pushing to spreadsheet:", error);
      });
    } catch (error) {
      console.error("Error processing voice command:", error);
      const errorResponse = {
        role: "assistant",
        content: "Sorry, I encountered an error processing your voice command.",
      };
      setMessages((prev) => [errorResponse, ...prev]);
    } finally {
      setLoading(false);
    }
  };
  // Thêm function updateSpectrum
  const updateSpectrum = () => {
    if (!analyserr) return;

    const dataArray = new Uint8Array(analyserr.frequencyBinCount);
    analyserr.getByteFrequencyData(dataArray);

    const sampleRate = context.sampleRate;
    const binWidth = sampleRate / analyserr.fftSize;

    const freq100 = Math.floor(100 / binWidth);
    const freq500 = Math.floor(500 / binWidth);
    const freq2k = Math.floor(2000 / binWidth);
    const freq6k = Math.floor(6000 / binWidth);

    const avg1 = average(dataArray.slice(0, freq100));
    const avg2 = average(dataArray.slice(freq100, freq500));
    const avg3 = average(dataArray.slice(freq500, freq2k));
    const avg4 = average(dataArray.slice(freq2k, freq6k));

    setPoint1({ y: mapRange(avg1, 0, 255, 100, 100 + SpectrumAMP) });
    setPoint2({ x: mapRange(avg2, 0, 255, 0, -SpectrumAMP) });
    setPoint3({ y: mapRange(avg3, 0, 255, 0, -SpectrumAMP) });
    setPoint4({ x: mapRange(avg4, 0, 255, 100, 100 + SpectrumAMP) });

    if (isSpeakBusy) {
      animationRef.current = requestAnimationFrame(updateSpectrum);
    }
  };

  // Thêm utility functions
  function average(array) {
    return array.reduce((a, b) => a + b, 0) / array.length;
  }

  function mapRange(value, inMin, inMax, outMin, outMax) {
    return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }

  const [transcript, setTranscript] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [isCallingAssistant, setIsCallingAssistant] = useState(false);
  const recognitionRef = useRef(null);
  let isForceListeningOff = true;

  // Thêm useEffect cho Speech Recognition
  useEffect(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = "en-US";

      recognition.onresult = async (event) => {
        const current = event.resultIndex;
        let transcriptText = event.results[current][0].transcript;

        // Thay thế "laura" thành "lara" (không phân biệt hoa thường)
        transcriptText = transcriptText.replace(/laura/gi, "Lara");

        setTranscript(transcriptText);

        if (
          transcriptText.toLowerCase().includes("lara") &&
          event.results[current].isFinal
        ) {
          await handleVoiceCommand(transcriptText);
        }
      };

      recognition.onspeechend = () => {
        console.log("Speech has stopped being detected");
        recognition.abort();
      };

      recognition.onend = () => {
        if (!isForceListeningOff) {
          recognition.start();
          console.log("Speech recognition restarted");
        }
      };

      recognitionRef.current = recognition;

      return () => {
        if (recognitionRef.current) {
          recognitionRef.current.stop();
        }
      };
    }
  }, []);

  // Thêm function toggle recognition
  const toggleSynthDetector = () => {
    if (isListening) {
      isForceListeningOff = true;
      setIsListening(false);
      recognitionRef.current?.stop();
    } else {
      isForceListeningOff = false;
      recognitionRef.current?.start();
      setIsListening(true);
    }
  };

  return (
    <div className={`app-container ${isMobile ? "MbileLayout" : ""}`}>
      <header className="header">
        <div className="header-left">
          <h1>Lara | Open</h1>
          <div className="status-dot"></div>
        </div>
        <div className="header-right">
          <button className="icon-button notification">
            <img
              className="notification-icon"
              src={BellIcon}
              alt="notification"
            />
          </button>
          <button className="icon-button settings">
            <img className="settings-icon" src={SettingIcon} alt="settings" />
          </button>
        </div>
      </header>

      <div className={`two-column-layout`}>
        <div className="left-column">
          <div
            className={`messages-container ${
              !isBootFinish ? "ContentAwaiting" : ""
            }`}
          >
            {!isBootFinish && (
              <lord-icon
                src="https://cdn.lordicon.com/gkryirhd.json"
                trigger="loop"
                state="loop-snake-alt"
                colors="primary:#ffffff"
                style={{ width: "78px", height: "78px" }}
              ></lord-icon>
            )}
            {loading && (
              <div className="message assistant ContentAwaiting">
                <lord-icon
                  src="https://cdn.lordicon.com/lqxfrxad.json"
                  colors="primary:#ffffff"
                  trigger="loop"
                  state="loop-scale"
                  style={{ width: "64px", height: "32px" }}
                ></lord-icon>
              </div>
            )}
            {messages.map((msg, index) => (
              <div key={index} className={`message ${msg.role}`}>
                <div
                  className="message-content"
                  dangerouslySetInnerHTML={{
                    __html: formatContent(msg.content),
                  }}
                />
              </div>
            ))}
          </div>

          <div className="input-area">
            <div className="input-container">
              <div className="text-input">
                <img className="message-icon" src={MessageIcon} alt="message" />
                <input
                  type="text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !loading && input.trim()) {
                      handleSubmit(e);
                    }
                  }}
                  placeholder="Enter message..."
                  disabled={loading}
                />
              </div>
              <button className="media-button">
                <img className="image-icon" src={ImageIcon} alt="image" />
              </button>
              <button className="media-button">
                <img className="audio-icon" src={AudioIcon} alt="audio" />
              </button>
              <button
                className="send-button"
                onClick={handleSubmit}
                disabled={loading}
              >
                <img className="send-icon" src={PointerIcon} alt="send" />
              </button>
            </div>
          </div>
        </div>

        <div className="right-column">
          <h2>Configuration Panel</h2>
          <div className="music-spectrum-container">
            <svg
              id="SynthDetector"
              width="100%"
              height="100%"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
              onClick={toggleSynthDetector}
              className={`${isListening ? "active" : ""} ${
                isCallingAssistant ? "calling" : ""
              }`}
            >
              <path
                d={`
             M ${point4.x} 50
             C ${point4.x} 77.6142  77.6142 ${point1.y}  50 ${point1.y}
             C 22.3858 ${point1.y}  ${point2.x} 77.6142  ${point2.x} 50
             C ${point2.x} 22.3858  22.3858 ${point3.y}  50 ${point3.y}
             C 77.6142 ${point3.y}  ${point4.x} 22.3858  ${point4.x} 50
             Z`}
                style={{
                  transform: `rotate(${mapRange(
                    100 - point1.y,
                    100,
                    100 + SpectrumAMP,
                    -132,
                    0
                  )}deg) scale(${mapRange(
                    point1.y,
                    100,
                    100 + SpectrumAMP,
                    1,
                    1.23
                  )})`,
                  transformOrigin: "center",
                }}
              />
              <path
                d={`
            M ${point4.x} 50
            C ${point4.x} 77.6142  77.6142 ${point1.y}  50 ${point1.y}
            C 22.3858 ${point1.y}  ${point2.x} 77.6142  ${point2.x} 50
            C ${point2.x} 22.3858  22.3858 ${point3.y}  50 ${point3.y}
            C 77.6142 ${point3.y}  ${point4.x} 22.3858  ${point4.x} 50
            Z`}
                style={{
                  transform: `rotate(${mapRange(
                    point1.y,
                    100,
                    100 + SpectrumAMP,
                    0,
                    102
                  )}deg) scale(${mapRange(
                    point1.y,
                    100,
                    100 + SpectrumAMP,
                    1,
                    1.36
                  )})`,
                  transformOrigin: "center",
                }}
              />
              <path
                d={`
            M ${point4.x} 50
            C ${point4.x} 77.6142  77.6142 ${point1.y}  50 ${point1.y}
            C 22.3858 ${point1.y}  ${point2.x} 77.6142  ${point2.x} 50
            C ${point2.x} 22.3858  22.3858 ${point3.y}  50 ${point3.y}
            C 77.6142 ${point3.y}  ${point4.x} 22.3858  ${point4.x} 50
            Z`}
              />{" "}
              <path
                d={`
            M ${point4.x} 50
            C ${point4.x} 77.6142  77.6142 ${point1.y}  50 ${point1.y}
            C 22.3858 ${point1.y}  ${point2.x} 77.6142  ${point2.x} 50
            C ${point2.x} 22.3858  22.3858 ${point3.y}  50 ${point3.y}
            C 77.6142 ${point3.y}  ${point4.x} 22.3858  ${point4.x} 50
            Z`}
              />{" "}
              <path
                d={`
            M ${point4.x} 50
            C ${point4.x} 77.6142  77.6142 ${point1.y}  50 ${point1.y}
            C 22.3858 ${point1.y}  ${point2.x} 77.6142  ${point2.x} 50
            C ${point2.x} 22.3858  22.3858 ${point3.y}  50 ${point3.y}
            C 77.6142 ${point3.y}  ${point4.x} 22.3858  ${point4.x} 50
            Z`}
              />
              {/* ... */}
              <path
                id="CenterPath"
                d="M 100 50 C 100 77.6142 77.6142 100 50 100 C 22.3858 100 0 77.6142 0 50 C 0 22.3858 22.3858 0 50 0 C 77.6142 0 100 22.3858 100 50 Z"
                style={{
                  transform: `scale(${mapRange(
                    point1.y,
                    100,
                    100 + SpectrumAMP,
                    1,
                    1.12
                  )})`,
                  transformOrigin: "center",
                }}
              />
              <path
                id="CenterPath2"
                d="M 100 50 C 100 77.6142 77.6142 100 50 100 C 22.3858 100 0 77.6142 0 50 C 0 22.3858 22.3858 0 50 0 C 77.6142 0 100 22.3858 100 50 Z"
                style={{
                  transform: `scale(${mapRange(
                    point1.y,
                    100,
                    100 + SpectrumAMP,
                    1,
                    1.23
                  )})`,
                  transformOrigin: "center",
                }}
              />
            </svg>
            <button className="SyntButton">
              {isListening ? "Stop" : "Start"}
              <br />
              Synth
            </button>
          </div>
          {/* Thêm transcript display nếu cần */}
          {transcript && (
            <div className="transcript">
              <p>{transcript}</p>
            </div>
          )}
        </div>
      </div>

      {/* Thêm audio player element */}
      <audio ref={audioRef} style={{ display: "none" }} />
    </div>
  );
};

export default LaraOpen;

import React, { useState, useEffect, useRef } from "react";
import "../Styles/style.css";

function MusicSpectrum() {
  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const animationRef = useRef(null);

  // State cho các điểm điều khiển SVG
  const [point1, setPoint1] = useState({ y: 100 });
  const [point2, setPoint2] = useState({ x: 0 });
  const [point3, setPoint3] = useState({ y: 0 });
  const [point4, setPoint4] = useState({ x: 100 });

  // Thêm biến điều khiển biên độ
  const SpectrumAMP = 20;

  useEffect(() => {
    // Khởi tạo Audio Context
    const context = new (window.AudioContext || window.webkitAudioContext)();
    const analyserNode = context.createAnalyser();
    analyserNode.fftSize = 2048;
    setAudioContext(context);
    setAnalyser(analyserNode);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      if (context.state !== "closed") {
        context.close();
      }
    };
  }, []);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const audio = new Audio();
      audio.src = URL.createObjectURL(file);
      audioRef.current = audio;

      // Kết nối audio với analyser
      const source = audioContext.createMediaElementSource(audio);
      source.connect(analyser);
      analyser.connect(audioContext.destination);

      audio.addEventListener("play", () => setIsPlaying(true));
      audio.addEventListener("pause", () => setIsPlaying(false));
      audio.addEventListener("ended", () => setIsPlaying(false));
    }
  };
  var ischecked = false;
  const updateSpectrum = () => {
    if (!isPlaying || !analyser) return;

    const dataArray = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(dataArray);

    // Tính toán index cho mỗi tần số
    const sampleRate = audioContext.sampleRate; // thường là 44100Hz
    const binWidth = sampleRate / analyser.fftSize;

    // Chuyển đổi Hz sang bin index
    const freq100 = Math.floor(100 / binWidth);
    const freq500 = Math.floor(500 / binWidth);
    const freq2k = Math.floor(2000 / binWidth);
    const freq6k = Math.floor(6000 / binWidth);

    // Tính trung bình cho từng dải tần số
    const avg1 = average(dataArray.slice(0, freq100)); // 0-100Hz
    const avg2 = average(dataArray.slice(freq100, freq500)); // 100-500Hz
    const avg3 = average(dataArray.slice(freq500, freq2k)); // 500-2kHz
    const avg4 = average(dataArray.slice(freq2k, freq6k)); // 2k-6kHz

    // Map các giá trị vào range của mỗi điểm điều khiển
    setPoint1({ y: mapRange(avg1, 0, 255, 100, 100 + SpectrumAMP) });
    setPoint2({ x: mapRange(avg2, 0, 255, 0, -SpectrumAMP) });
    setPoint3({ y: mapRange(avg3, 0, 255, 0, -SpectrumAMP) });
    setPoint4({ x: mapRange(avg4, 0, 255, 100, 100 + SpectrumAMP) });

    animationRef.current = requestAnimationFrame(updateSpectrum);
  };

  useEffect(() => {
    if (isPlaying) {
      updateSpectrum();
    }
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isPlaying]);

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
    }
  };

  return (
    <div className="music-spectrum-container" id="ContainerSpectrumOnly">
      <div className="controls">
        <input
          type="file"
          accept="audio/*"
          onChange={handleFileUpload}
          id="AudioInput"
        />
        <audio id="AudioSpectrumOnly" ref={audioRef} />
        <button id="PlayButtonSpectrumOnly" onClick={togglePlay}>
          {isPlaying ? "Pause" : "Play"}
        </button>
      </div>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d={`
            M ${point4.x} 50
            C ${point4.x} 77.6142  77.6142 ${point1.y}  50 ${point1.y}
            C 22.3858 ${point1.y}  ${point2.x} 77.6142  ${point2.x} 50
            C ${point2.x} 22.3858  22.3858 ${point3.y}  50 ${point3.y}
            C 77.6142 ${point3.y}  ${point4.x} 22.3858  ${point4.x} 50
            Z`}
          style={{
            transform: `rotate(${mapRange(
              100 - point1.y,
              100,
              100 + SpectrumAMP,
              -132,
              0
            )}deg) scale(${mapRange(
              point1.y,
              100,
              100 + SpectrumAMP,
              1,
              1.23
            )})`,
            transformOrigin: "center",
          }}
        />
        <path
          d={`
            M ${point4.x} 50
            C ${point4.x} 77.6142  77.6142 ${point1.y}  50 ${point1.y}
            C 22.3858 ${point1.y}  ${point2.x} 77.6142  ${point2.x} 50
            C ${point2.x} 22.3858  22.3858 ${point3.y}  50 ${point3.y}
            C 77.6142 ${point3.y}  ${point4.x} 22.3858  ${point4.x} 50
            Z`}
          style={{
            transform: `rotate(${mapRange(
              point1.y,
              100,
              100 + SpectrumAMP,
              0,
              102
            )}deg) scale(${mapRange(
              point1.y,
              100,
              100 + SpectrumAMP,
              1,
              1.36
            )})`,
            transformOrigin: "center",
          }}
        />
        <path
          d={`
            M ${point4.x} 50
            C ${point4.x} 77.6142  77.6142 ${point1.y}  50 ${point1.y}
            C 22.3858 ${point1.y}  ${point2.x} 77.6142  ${point2.x} 50
            C ${point2.x} 22.3858  22.3858 ${point3.y}  50 ${point3.y}
            C 77.6142 ${point3.y}  ${point4.x} 22.3858  ${point4.x} 50
            Z`}
        />{" "}
        <path
          d={`
            M ${point4.x} 50
            C ${point4.x} 77.6142  77.6142 ${point1.y}  50 ${point1.y}
            C 22.3858 ${point1.y}  ${point2.x} 77.6142  ${point2.x} 50
            C ${point2.x} 22.3858  22.3858 ${point3.y}  50 ${point3.y}
            C 77.6142 ${point3.y}  ${point4.x} 22.3858  ${point4.x} 50
            Z`}
        />{" "}
        <path
          d={`
            M ${point4.x} 50
            C ${point4.x} 77.6142  77.6142 ${point1.y}  50 ${point1.y}
            C 22.3858 ${point1.y}  ${point2.x} 77.6142  ${point2.x} 50
            C ${point2.x} 22.3858  22.3858 ${point3.y}  50 ${point3.y}
            C 77.6142 ${point3.y}  ${point4.x} 22.3858  ${point4.x} 50
            Z`}
        />
        <path
          id="CenterPath"
          d="M 100 50 C 100 77.6142 77.6142 100 50 100 C 22.3858 100 0 77.6142 0 50 C 0 22.3858 22.3858 0 50 0 C 77.6142 0 100 22.3858 100 50 Z"
          style={{
            transform: `scale(${mapRange(
              point1.y,
              100,
              100 + SpectrumAMP,
              1,
              1.12
            )})`,
            transformOrigin: "center",
          }}
        />
        <path
          id="CenterPath2"
          d="M 100 50 C 100 77.6142 77.6142 100 50 100 C 22.3858 100 0 77.6142 0 50 C 0 22.3858 22.3858 0 50 0 C 77.6142 0 100 22.3858 100 50 Z"
          style={{
            transform: `scale(${mapRange(
              point1.y,
              100,
              100 + SpectrumAMP,
              1,
              1.23
            )})`,
            transformOrigin: "center",
          }}
        />
      </svg>
    </div>
  );
}

// Hàm tiện ích
function average(array) {
  return array.reduce((a, b) => a + b, 0) / array.length;
}

function mapRange(value, inMin, inMax, outMin, outMax) {
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

export default MusicSpectrum;

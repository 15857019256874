import React, { useState } from "react";
import "../Styles/style.css";

function PlayWithSVG() {
  const [point1, setPoint1] = useState({ y: 100 });
  const [point2, setPoint2] = useState({ x: 0 });
  const [point3, setPoint3] = useState({ y: 0 });
  const [point4, setPoint4] = useState({ x: 100 });

  return (
    <div className="play-with-svg-container">
      <div className="controls-container">
        <div className="control-group">
          <h3>Control Point 1</h3>
          <div>
            <label>Y: </label>
            <input
              type="range"
              min="100"
              max="200"
              value={point1.y}
              onChange={(e) =>
                setPoint1({ ...point1, y: Number(e.target.value) })
              }
            />
            <span>{point1.y}</span>
          </div>
        </div>

        <div className="control-group">
          <h3>Control Point 2</h3>
          <div>
            <label>X: </label>
            <input
              type="range"
              min="-100"
              max="0"
              value={point2.x}
              onChange={(e) =>
                setPoint2({ ...point2, x: Number(e.target.value) })
              }
            />
            <span>{point2.x}</span>
          </div>
        </div>

        <div className="control-group">
          <h3>Control Point 3</h3>
          <div>
            <label>Y: </label>
            <input
              type="range"
              min="-100"
              max="0"
              value={point3.y}
              onChange={(e) =>
                setPoint3({ ...point3, y: Number(e.target.value) })
              }
            />
            <span>{point3.y}</span>
          </div>
        </div>

        <div className="control-group">
          <h3>Control Point 4</h3>
          <div>
            <label>X: </label>
            <input
              type="range"
              min="100"
              max="200"
              value={point4.x}
              onChange={(e) =>
                setPoint4({ ...point4, x: Number(e.target.value) })
              }
            />
            <span>{point4.x}</span>
          </div>
        </div>
      </div>

      <svg
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d={`
            M ${point4.x} 50
            C ${point4.x} 77.6142  77.6142 ${point1.y}  50 ${point1.y}
            C 22.3858 ${point1.y}  ${point2.x} 77.6142  ${point2.x} 50
            C ${point2.x} 22.3858  22.3858 ${point3.y}  50 ${point3.y}
            C 77.6142 ${point3.y}  ${point4.x} 22.3858  ${point4.x} 50
            Z`}
          fill="currentColor"
        />
      </svg>
    </div>
  );
}

export default PlayWithSVG;
